import { PostPublication } from '@/data/objects/post-publication-objects'
import { Card, CardProps, Text } from '@amstudio/react-native-components'
import { ProfilePicture } from '@/features/users/components/ProfilePicture'
import {
  Pressable,
  ScrollView,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useTheme } from '@amstudio/react-native-config'
import { Href } from 'expo-router'
import { formatTimestamp } from '@amstudio/app-utils'
import { Image } from 'expo-image'
import { NoticeDetailsModal, usePostActions } from '@/features/publications'
import { useModalRouter } from '@/features/design/navigation/useModalRouter'
import { formatFullName } from '@/utils/user-utils'

interface Props {
  post: PostPublication
  onPress?: (() => void) | { uri: Href } | 'auto-linked'
  style?: ViewStyle
  backgroundColor?: CardProps['backgroundColor']
}

export function PostCard({ post, onPress, style, backgroundColor }: Props) {
  const { colors } = useTheme()
  const { togglePostLike, likedStatus } = usePostActions(post)
  const modalRouter = useModalRouter()

  const handleCardPress = () => {
    if (typeof onPress === 'function') {
      onPress()
    } else if (onPress === 'auto-linked' || onPress === undefined) {
      // Use modal router to either navigate or show dialog
      modalRouter.openAsModal(
        <NoticeDetailsModal noticeId={post.postId} />,
        `/(authenticated)/property/notices/${post.postId}`
      )
    } else if (typeof onPress === 'object' && 'uri' in onPress) {
      // This is an object with an uri property
      modalRouter.openAsModal(
        <NoticeDetailsModal noticeId={post.postId} />,
        onPress.uri
      )
    }
  }

  const surtitle = `${formatFullName(
    post.author.firstName,
    post.author.lastName
  ).trim()} · ${
    post.postedTo === 'neighborhood_property_boards'
      ? `${post.author.propertyName.trim()} ·`
      : ''
  } ${formatTimestamp(post.createdAt)}`

  return (
    <Card
      backgroundColor={backgroundColor}
      style={[
        styles.container,
        {
          borderColor: colors.background.primary,
          borderWidth: 4,
        },
        style ?? {},
      ]}
      onPress={handleCardPress}
      hoverAnimationEnabled
    >
      <View style={styles.imageContainer}>
        <ProfilePicture
          userId={post.author.userId}
          source={post.author.profilePicture}
          firstName={post.author.firstName}
          lastName={post.author.lastName}
          size={38}
        />
      </View>

      <View style={styles.contentContainer}>
        <View style={styles.headerContainer}>
          <View>
            <Text
              variant={'caption'}
              color={'secondary'}
              style={{
                marginBottom: 8,
              }}
            >
              {surtitle}
            </Text>
            {!!post.subject?.trim() && (
              <Text
                variant={'title4'}
                style={{ fontWeight: 'bold', marginBottom: 4 }}
              >
                {post.subject.trim() + (post.content?.trim() ? '\n' : '')}
              </Text>
            )}
            <Text variant={'bodyText'}>{post.content?.trim()}</Text>

            {post.attachedImages.length > 0 && (
              <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                {post.attachedImages.map((image) => (
                  <Image
                    key={image.id}
                    source={image.original}
                    style={{
                      height: 300,
                      aspectRatio: image.aspectRatio,
                      borderRadius: 15,
                      marginRight: 12,
                      marginVertical: 8,
                    }}
                    contentFit={'cover'}
                  />
                ))}
              </ScrollView>
            )}
          </View>
        </View>
      </View>

      <View style={styles.actionContainer}>
        <Pressable
          style={styles.action}
          hitSlop={15}
          onPress={(e) => {
            e.preventDefault()
            togglePostLike()
          }}
        >
          <MaterialCommunityIcons
            name={likedStatus.liked ? 'heart' : 'heart-outline'}
            size={22}
            color={colors.text.primary}
          />
          <Text variant={'bodyText'}>{likedStatus.count}</Text>
        </Pressable>
        <View style={styles.action}>
          <MaterialCommunityIcons
            name={'comment-outline'}
            size={22}
            color={colors.text.primary}
          />
          <Text variant={'bodyText'}>{post.totalComments}</Text>
        </View>
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    columnGap: 16,
    overflow: 'hidden',
  },
  imageContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  contentContainer: {
    flex: 1,
    rowGap: 16,
  },
  headerContainer: {},
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionContainer: {
    flexDirection: 'row',
    columnGap: 16,
    alignItems: 'flex-end',
  },
  action: {
    flexDirection: 'row',
    columnGap: 6,
    alignItems: 'center',
  },
})
