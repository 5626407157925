import { useMemo } from 'react'
import {
  energyClasses,
  EnergyClassificationDetails,
} from '@/data/objects/energy-objects'
import { useBuildingEnergyRatings } from '@/features/neighbourhood/hooks/useBuildingEnergyRatings'

export function usePropertyEnergyRating(propertyId: number) {
  const { data, isLoading } = useBuildingEnergyRatings()

  const propertyToEnergyClass: Record<number, EnergyClassificationDetails> =
    useMemo(
      () =>
        data?.reduce(
          (acc, building) => ({
            ...acc,
            [building.property.id]: energyClasses[building.energyClass],
          }),
          {}
        ) ?? {},
      [data]
    )

  return { data: propertyToEnergyClass[propertyId], isLoading }
}

export function usePropertyEnergyBuilding() {
  const { data, isLoading } = useBuildingEnergyRatings()

  const propertyToEnergyBuilding: Record<
    number,
    {
      energyClass: EnergyClassificationDetails
      coordinates: { lat: number; lng: number }
    }
  > = useMemo(
    () =>
      data?.reduce(
        (acc, building) => ({
          ...acc,
          [building.property.id]: {
            energyClass: energyClasses[building.energyClass],
            coordinates: building.coordinates,
          },
        }),
        {}
      ) ?? {},
    [data]
  )

  return { propertyToEnergyBuilding, isLoading }
}
