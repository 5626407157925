import { useCallback, useEffect, useRef, useState } from 'react'
import { NeighbourhoodPropertyList } from '../components/NeighbourhoodPropertyList'
import { useMyNeighbourhoodProperties } from '../hooks/useMyNeighbourhoodProperties'
import { NeighbourhoodMapMarker } from '../components/types'
import { EnergyClassification } from '@/data/objects/energy-objects'
import { FlatList, ViewStyle } from 'react-native'

interface NeighbourhoodPropertyListContainerProps {
  onHighlightMarker: (marker: NeighbourhoodMapMarker | undefined) => void
  highlightedMarker?: NeighbourhoodMapMarker
  onFilteredPropertiesChange?: (filteredProperties: any[]) => void
  style?: ViewStyle
}

export function NeighbourhoodPropertyListContainer({
  onHighlightMarker,
  highlightedMarker,
  onFilteredPropertiesChange,
  style,
}: NeighbourhoodPropertyListContainerProps) {
  const [searchText, setSearchText] = useState('')
  const [selectedEnergyClass, setSelectedEnergyClass] = useState<
    EnergyClassification | undefined
  >(undefined)

  const listRef = useRef<FlatList>(null)

  const { data: properties, isLoading: isLoadingProperties } =
    useMyNeighbourhoodProperties()

  const filteredProperties = useCallback(
    (
      properties:
        | ReturnType<typeof useMyNeighbourhoodProperties>['data']
        | undefined,
      energyClass: EnergyClassification | undefined,
      search: string
    ) => {
      let filtered = properties ?? []

      // Filter by energy class if specified
      if (energyClass) {
        filtered = filtered.filter(
          (property) => property.energyClass?.classification === energyClass
        )
      }

      // Filter by search text if provided
      if (search.trim() !== '') {
        filtered = filtered.filter(
          (property) =>
            property.name.toLowerCase().includes(search.toLowerCase()) ||
            property.propertyType?.toLowerCase().includes(search.toLowerCase())
        )
      }

      return filtered
    },
    []
  )

  const handleSearchChange = (text: string) => {
    setSearchText(text)
  }

  const scrollToPropertyWithId = (propertyId: number) => {
    const index = properties?.findIndex(
      (property) => property.id === propertyId
    )

    if (!!index && index !== -1) {
      try {
        listRef.current?.scrollToIndex({
          animated: true,
          index,
          viewPosition: 0.5, // Center the item in the list
          viewOffset: 0,
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleHighlightProperty = (
    property: NeighbourhoodMapMarker | undefined
  ) => {
    onHighlightMarker(property)
    if (property?.id) {
      scrollToPropertyWithId(property.id)
    }
  }

  const filteredPropertiesList = filteredProperties(
    properties,
    selectedEnergyClass,
    searchText
  )

  // Notify parent component when filtered properties change
  useEffect(() => {
    if (onFilteredPropertiesChange) {
      onFilteredPropertiesChange(filteredPropertiesList)
    }
  }, [filteredPropertiesList, onFilteredPropertiesChange])

  return (
    <NeighbourhoodPropertyList
      properties={properties ?? []}
      isLoading={isLoadingProperties}
      onHighlightProperty={handleHighlightProperty}
      highlightedProperty={highlightedMarker}
      selectedEnergyClass={selectedEnergyClass}
      onSelectEnergyClass={setSelectedEnergyClass}
      listRef={listRef}
      filteredProperties={filteredPropertiesList}
      onSearchChange={handleSearchChange}
      style={style}
    />
  )
}
