import { useLocalLifeServices } from '@/config/LocalLifeAppServices'
import { useQuery } from '@tanstack/react-query'
import { usePropertyEnergyBuilding } from '@/features/neighbourhood/hooks/usePropertyEnergyRating'
import { useMemo } from 'react'

export function useMyNeighbourhoodProperties() {
  const { neighbourhoodService } = useLocalLifeServices()

  const { propertyToEnergyBuilding, isLoading: isLoadingEnergyClasses } =
    usePropertyEnergyBuilding()

  const { data: neighbourhoodProperties, isLoading } = useQuery({
    queryKey: ['myNeighbourhoodProperties'],
    queryFn:
      neighbourhoodService.getMyNeighbourhoodProperties.bind(
        neighbourhoodService
      ),
  })

  const data = useMemo(() => {
    if (!neighbourhoodProperties || !propertyToEnergyBuilding) {
      return undefined
    }

    return neighbourhoodProperties
      .filter((p) =>
        Object.keys(propertyToEnergyBuilding).includes(p.id.toString())
      )
      .map((property) => ({
        ...property,
        energyClass: propertyToEnergyBuilding[property.id].energyClass,
        coordinates: propertyToEnergyBuilding[property.id].coordinates,
      }))
  }, [propertyToEnergyBuilding, neighbourhoodProperties])

  return {
    data,
    isLoading: isLoadingEnergyClasses || isLoading,
  }
}
